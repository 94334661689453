export const ColorsByDeptLevel = [
  'RebeccaPurple',
  'LimeGreen',
  'SaddleBrown',
  'Teal',
  'Salmon',
  'CornflowerBlue',
  'Tomato',
  'DarkOrchid',
  'MediumSeaGreen',
  'GoldenRod',
  'MediumPurple',
  'SteelBlue',
  'DarkOliveGreen',
  'MediumVioletRed',
  'DarkSlateGray',
  'MediumTurquoise',
  'IndianRed',
  'MediumBlue',
  'DarkGoldenRod',
  'MediumOrchid',
];

export type RealtaDocument = {
  uuid: string;
  shortName?: string;
  externalId?: string;
  displayName?: string;
  createdBy?: string;
  labels?: [string];
  jdx?: [string];
};

export type DocumentNode = {
  uuid: string;
  id?: string | null;
  fullId?: string | null;
  content?: string | null;
  startBoundryY: number;
  endBoundryY: number;
  // NOTE: if node has no startBoundryPage, it is a placeholder node
  // this placeholder node has no startBoundryY, endBoundryY, startBoundryPage, endBoundryPage
  // so must be disabled clicking on it
  startBoundryPage?: number;
  endBoundryPage: number;
  type?: string;
  fullSectionName?: string;
};

export type CodeBlock = {
  uuid: string;
  rule: string;
  version: number;
  createdBy?: string | null;
  updatedBy?: string | null;
  codeApproved?: boolean;
  atomsApproved?: boolean;
  created_at: Date;
  updated_at: Date;
};

export type Test = {
  uuid: string;
  name: string;
  startNode: string | null;
  fact: string | null;
  result: string | null;
  requiredResults: string | null;
  unwantedResults: string | null;
  status: string | null;
  description: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  created_at?: Date;
  updated_at?: Date;
};

export type Atom = {
  uuid: string;
  name: string;
  type: string;
  comment: string | null;
};

export type GraphNode = {
  elementId: string;
  uuid?: string;
  documentId?: string;
  label?: string;
  externalId?: string;
  shortName?: string;
  displayName?: string;
  fullId?: string;
  id?: string;
  startBoundryPage?: number;
  endBoundryPage?: number;
  startBoundryY?: number;
  endBoundryY?: number;
};

export type GraphLink = {
  elementId: string;
  label?: string;
  EndOffset?: number;
  BeginOffset?: number;
  Score?: number;
  Text?: string;
  startNodeElementId: string;
  endNodeElementId: string;
};

export type Hierarchy = {
  nodes: GraphNode[];
  links: GraphLink[];
};

export type Reference = {
  document: RealtaDocument;
  section: GraphNode;
  vSection: GraphNode;
  vDEFINES: GraphLink;
  vHAS_SECTIONS: GraphLink;
  INT_REF_DIRECT: GraphLink;
  hasChildren?: boolean;
};

export const DEFAULT_ATOM_SUGGESTION = ['Atom', 'Date', 'DateTime', 'Duration', 'Numeric', 'String'];

export interface DocumentDiff {
  uuid: string;
  fullIds: string[];
  joinFullId: string;
  startBoundryPage: number;
  endBoundryPage: number;
  startBoundryY: number;
  endBoundryY: number;
  parHash: string;
  sectionHash: string;
  content: string;
}

export enum DocumentDiffChangeType {
  ADDED = 'added',
  UPDATED = 'updated',
  DELETED = 'deleted',
}

export type DocumentDiffChange = DocumentDiff & {
  type: DocumentDiffChangeType;
  update?: DocumentDiffChange;
};

export type DocumentDiffChangeMap = Map<string, DocumentDiffChange>;

export type DocumentDiffResult = {
  doc1ChangeMap: DocumentDiffChangeMap;
  doc2ChangeMap: DocumentDiffChangeMap;
  mergedChangeMap: DocumentDiffChangeMap;
  tocChangeMap: DocumentDiffChangeMap;
};

export type LinkedRtp = {
  name: string;
  description: string;
};

export type Jurisdiction = {
  data: string;
};

export type Dataset = {
  data: string;
};

export type SearchItem = {
  document: RealtaDocument;
  section: DocumentNode;
  score: string;
  sourcePath: string;
};

export type SearchData = {
  items: SearchItem[];
  page: number;
  limit: number;
  total: number;
};

export const ATOM_REGEX = /^[a-zA-Z$][a-zA-Z$0-9_.]*$/;

export type MergeNode = {
  uuid: string;
  startBoundryPage: number | null;
  endBoundryPage: number | null;
  startBoundryY: number | null;
  endBoundryY: number | null;
};

export type CommentNode = {
  sectionId: string;
  documentId: string;
  content: string;
  isResolved: boolean;
  isDeleted: boolean;
  isReply: boolean;
  replies?: CommentNode[] | null;
};

export type MergeCandidate = {
  isSolved: boolean;
};

export type DocumentMerge = {
  idPath: string[];
  src: MergeNode | null;
  tgt: MergeNode | null;
  idPathMatch: boolean;
  sectionHashMatch: boolean;
  firstParHashMatch: boolean;
  srcComments?: CommentNode[] | null;
  tgtComments?: CommentNode[] | null;
  mergeCandidate: MergeCandidate | null;
};

export type CodeGenDebug = {
  success: boolean;
  sampleLinks: string[];
  type: string;
  initialTurnip: string;
  finalTurnip: string;
  outputMessage: string;
};

export type Tag = {
  uuid: string;
  name: string;
};
