import gql from 'graphql-tag';
import { apollo } from '../apollo/client';
import {
  Atom,
  CodeBlock,
  CodeGenDebug,
  DEFAULT_ATOM_SUGGESTION,
  Dataset,
  DocumentDiffChange,
  DocumentDiffChangeType,
  DocumentDiffResult,
  DocumentMerge,
  DocumentNode,
  Hierarchy,
  Jurisdiction,
  LinkedRtp,
  RealtaDocument,
  Reference,
  SearchData,
  Tag,
  Test,
} from '../types/RealtaDocument';
import { CommentCount } from '../types/Comment';

const GET_DOCUMENT_QUERY = gql`
  query GET_DOCUMENT_QUERY($uuid: String!, $roles: [String!]) {
    getDocument(uuid: $uuid, roles: $roles) {
      displayName
      externalId
      uuid
      labels
      shortName
    }
  }
`;
const getDocument = async ({
  uuid,
  roles,
}: {
  uuid: string;
  roles?: string[];
}): Promise<RealtaDocument | undefined> => {
  const { data } = await apollo.mutate({
    mutation: GET_DOCUMENT_QUERY,
    variables: {
      uuid,
      roles,
    },
  });
  return data?.getDocument;
};

const GET_DOCUMENTS_QUERY = gql`
  query GET_DOCUMENTS_QUERY($roles: [String!], $jdxs: [String], $datasets: [String], $title: String) {
    getAllDocumentMeta(roles: $roles, jdxs: $jdxs, datasets: $datasets, title: $title) {
      uuid
      externalId
      displayName
      labels
      jdx
      createdBy
    }
  }
`;
const getAllDocumentMeta = async ({
  roles,
  datasets,
  jurisdictions,
  title,
}: {
  roles?: string[];
  datasets?: string[];
  jurisdictions?: string[];
  title?: string;
}): Promise<RealtaDocument[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_DOCUMENTS_QUERY,
    variables: {
      roles,
      jdxs: jurisdictions,
      datasets,
      title,
    },
  });
  return (data?.getAllDocumentMeta as RealtaDocument[]) || [];
};

const GET_DOCUMENTS_OF_FOLDER = gql`
  query GET_DOCUMENTS_OF_FOLDER($folderUuid: String!) {
    getDocumentsOfFolder(folderUuid: $folderUuid) {
      uuid
      externalId
      displayName
      labels
      jdx
      createdBy
    }
  }
`;
const getDocumentsOfFolder = async (folderUuid: string): Promise<RealtaDocument[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_DOCUMENTS_OF_FOLDER,
    variables: {
      folderUuid,
    },
  });
  return (data?.getDocumentsOfFolder as RealtaDocument[]) || [];
};

const GET_SECTIONS_BY_PAGE_QUERY = gql`
  query GET_SECTIONS_BY_PAGE_QUERY($documentUuid: String!, $page: Float!) {
    getDocumentSectionsByPage(documentUuid: $documentUuid, page: $page) {
      uuid
      id
      fullId
      startBoundryY
      endBoundryY
      startBoundryPage
      endBoundryPage
      type
    }
  }
`;
const getDocumentSectionsByPage = async ({
  documentUuid,
  page,
}: {
  documentUuid: string;
  page: number;
}): Promise<DocumentNode[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_SECTIONS_BY_PAGE_QUERY,
    variables: {
      documentUuid,
      page,
    },
  });
  return (data?.getDocumentSectionsByPage as DocumentNode[]) || [];
};

const GET_DOCUMENT_HEADING_SECTIONS_QUERY = gql`
  query GET_DOCUMENT_HEADING_SECTIONS_QUERY($documentUuid: String!) {
    getDocumentHeadingSections(documentUuid: $documentUuid) {
      uuid
      id
      fullId
      startBoundryY
      endBoundryY
      startBoundryPage
      endBoundryPage
    }
  }
`;
const getDocumentHeadingSections = async ({ documentUuid }: { documentUuid: string }): Promise<DocumentNode[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_DOCUMENT_HEADING_SECTIONS_QUERY,
    variables: {
      documentUuid,
    },
  });
  return (data?.getDocumentHeadingSections as DocumentNode[]) || [];
};

const GET_NODE_SUB_SECTION_COMMENTS_QUERY = gql`
  query GET_NODE_SUB_SECTION_COMMENTS_QUERY($uuid: ID!, $isDocument: Boolean!) {
    getNodeSubSectionComments(uuid: $uuid, isDocument: $isDocument) {
      id
      comments
      resolved
    }
  }
`;
const getNodeSubSectionComments = async ({
  uuid,
  isDocument,
}: {
  uuid: string;
  isDocument: boolean;
}): Promise<CommentCount[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_NODE_SUB_SECTION_COMMENTS_QUERY,
    variables: {
      uuid,
      isDocument,
    },
  });
  return (data?.getNodeSubSectionComments as CommentCount[]) || [];
};

const GET_SECTION_QUERY = gql`
  query GET_SECTION_QUERY($uuid: String!) {
    getSection(uuid: $uuid) {
      uuid
      id
      fullId
      startBoundryY
      endBoundryY
      startBoundryPage
      endBoundryPage
      type
      fullSectionName
    }
  }
`;
const getSection = async ({ uuid }: { uuid: string }): Promise<DocumentNode | undefined> => {
  const { data } = await apollo.mutate({
    mutation: GET_SECTION_QUERY,
    variables: {
      uuid,
    },
  });
  return data?.getSection;
};

const GET_SUB_SECTIONS_OF_SECTION_QUERY = gql`
  query GET_SUB_SECTIONS_OF_SECTION_QUERY($sectionUuid: String!) {
    getSubSectionsOfSection(sectionUuid: $sectionUuid) {
      uuid
      id
      fullId
      startBoundryY
      endBoundryY
      startBoundryPage
      endBoundryPage
    }
  }
`;
const getSubSectionsOfSection = async ({ sectionUuid }: { sectionUuid: string }): Promise<DocumentNode[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_SUB_SECTIONS_OF_SECTION_QUERY,
    variables: {
      sectionUuid,
    },
  });
  return (data?.getSubSectionsOfSection as DocumentNode[]) || [];
};

const GET_PARAGRAPHS_BY_PAGE_QUERY = gql`
  query GET_PARAGRAPHS_BY_PAGE_QUERY($documentUuid: String!, $page: Float!) {
    getDocumentParagraphsByPage(documentUuid: $documentUuid, page: $page) {
      uuid
      id
      content
      startBoundryY
      endBoundryY
      startBoundryPage
      endBoundryPage
      type
    }
  }
`;
const getDocumentParagraphsByPage = async ({
  documentUuid,
  page,
}: {
  documentUuid: string;
  page: number;
}): Promise<DocumentNode[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_PARAGRAPHS_BY_PAGE_QUERY,
    variables: {
      documentUuid,
      page,
    },
  });
  return (data?.getDocumentParagraphsByPage as DocumentNode[]) || [];
};

const GET_CODE_BLOCK_BY_NODE_ID = gql`
  query GET_CODE_BLOCK_BY_NODE_ID($parentNodeUuid: String!) {
    getCodeBlockByNodeId(parentNodeUuid: $parentNodeUuid) {
      uuid
      rule
      codeApproved
      atomsApproved
      createdBy
      updatedBy
    }
  }
`;
const getCodeBlockByNodeId = async ({ parentNodeUuid }: { parentNodeUuid: string }): Promise<CodeBlock | undefined> => {
  const { data } = await apollo.mutate({
    mutation: GET_CODE_BLOCK_BY_NODE_ID,
    fetchPolicy: 'no-cache',
    variables: {
      parentNodeUuid,
    },
  });
  return (data?.getCodeBlockByNodeId as CodeBlock[])?.[0];
};

const CREATE_CODE_BLOCK = gql`
  mutation CREATE_CODE_BLOCK($rule: String!, $parentNode: String!, $createdBy: String) {
    createCodeBlock(rule: $rule, parentNode: $parentNode, createdBy: $createdBy) {
      uuid
      rule
      codeApproved
      atomsApproved
      createdBy
      updatedBy
    }
  }
`;
const createCodeBlock = async ({
  rule,
  parentNode,
  createdBy,
}: {
  rule: string;
  parentNode: string;
  createdBy: string;
}): Promise<CodeBlock | undefined> => {
  const { data } = await apollo.mutate({
    mutation: CREATE_CODE_BLOCK,
    fetchPolicy: 'no-cache',
    variables: {
      rule,
      parentNode,
      createdBy,
    },
  });
  return (data?.createCodeBlock as CodeBlock[])?.[0];
};

const UPDATE_CODE_BLOCK = gql`
  mutation UPDATE_CODE_BLOCK($uuid: String!, $rule: String!, $updatedBy: String) {
    updateCodeBlock(uuid: $uuid, rule: $rule, updatedBy: $updatedBy) {
      uuid
      rule
      codeApproved
      atomsApproved
      createdBy
      updatedBy
    }
  }
`;
const updateCodeBlock = async ({
  uuid,
  rule,
  updatedBy,
}: {
  uuid: string;
  rule: string;
  updatedBy: string;
}): Promise<CodeBlock | undefined> => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_CODE_BLOCK,
    variables: {
      uuid,
      rule,
      updatedBy,
    },
  });
  return data?.updateCodeBlock;
};

const REVIEW_CODE_BLOCK = gql`
  mutation REVIEW_CODE_BLOCK($uuid: String!, $codeApproved: Boolean, $atomsApproved: Boolean) {
    reviewCodeBlock(uuid: $uuid, codeApproved: $codeApproved, atomsApproved: $atomsApproved) {
      uuid
      rule
      codeApproved
      atomsApproved
      createdBy
      updatedBy
    }
  }
`;
const reviewCodeBlock = async ({
  uuid,
  codeApproved,
  atomsApproved,
}: {
  uuid: string;
  codeApproved?: boolean;
  atomsApproved?: boolean;
}): Promise<CodeBlock | undefined> => {
  const { data } = await apollo.mutate({
    mutation: REVIEW_CODE_BLOCK,
    variables: {
      uuid,
      codeApproved,
      atomsApproved,
    },
  });
  return data?.reviewCodeBlock;
};

const GET_SECTION_HIERARCHY = gql`
  query GET_SECTION_HIERARCHY($uuid: String!, $depth: Float!, $upstream: Boolean) {
    getSectionHierarchy(uuid: $uuid, depth: $depth, upstream: $upstream) {
      nodes {
        elementId
        uuid
        documentId
        label
        externalId
        displayName
        fullId
        id
        startBoundryPage
        endBoundryPage
        startBoundryY
        endBoundryY
      }
      links {
        elementId
        label
        EndOffset
        BeginOffset
        Score
        Text
        startNodeElementId
        endNodeElementId
      }
    }
  }
`;
const getSectionHierarchy = async ({
  uuid,
  depth,
  upstream,
}: {
  uuid: string;
  depth: number;
  upstream: boolean;
}): Promise<Hierarchy | undefined> => {
  const { data } = await apollo.mutate({
    mutation: GET_SECTION_HIERARCHY,
    variables: {
      uuid,
      upstream,
      depth,
    },
  });
  return data?.getSectionHierarchy as Hierarchy;
};

const GET_SECTION_REFERENCES = gql`
  query ($uuid: String!, $rootNodeIds: [String!]!, $upstream: Boolean) {
    getSectionReferences(uuid: $uuid, rootNodeIds: $rootNodeIds, upstream: $upstream) {
      document {
        uuid
        displayName
        createdBy
      }
      section {
        uuid
        elementId
        startBoundryPage
      }
      vSection {
        uuid
        elementId
        fullId
      }
      vDEFINES {
        elementId
        startNodeElementId
        endNodeElementId
        Text
      }
      vHAS_SECTIONS {
        elementId
        startNodeElementId
        endNodeElementId
        Text
      }
      INT_REF_DIRECT {
        elementId
        startNodeElementId
        endNodeElementId
        Text
      }
      hasChildren
    }
  }
`;
const getSectionReferences = async ({
  uuid,
  upstream,
  rootNodeIds,
}: {
  uuid: string;
  upstream: boolean;
  rootNodeIds: string[];
}): Promise<Reference[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_SECTION_REFERENCES,
    variables: {
      uuid,
      upstream,
      rootNodeIds,
    },
  });
  return data?.getSectionReferences as Reference[];
};

const GET_TESTS_BY_NODE_ID = gql`
  query GET_TESTS_BY_NODE_ID($parentNodeUuid: String!) {
    getTestsByNodeId(parentNodeUuid: $parentNodeUuid) {
      uuid
      name
      startNode
      fact
      result
      description
      status
      requiredResults
      unwantedResults
      createdBy
      updatedBy
    }
  }
`;
const getTestsByNodeId = async ({ parentNodeUuid }: { parentNodeUuid: string }): Promise<Test[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_TESTS_BY_NODE_ID,
    fetchPolicy: 'no-cache',
    variables: {
      parentNodeUuid,
    },
  });
  return data?.getTestsByNodeId as Test[];
};

const CREATE_TEST = gql`
  mutation CREATE_TEST(
    $name: String!
    $parentNode: String!
    $description: String
    $fact: String
    $startNode: String
    $result: String
    $status: String
    $requiredResults: String
    $unwantedResults: String
    $createdBy: String
  ) {
    createTest(
      name: $name
      parentNode: $parentNode
      description: $description
      fact: $fact
      startNode: $startNode
      result: $result
      status: $status
      requiredResults: $requiredResults
      unwantedResults: $unwantedResults
      createdBy: $createdBy
    ) {
      uuid
      name
      startNode
      fact
      result
      description
      status
      requiredResults
      unwantedResults
      createdBy
      updatedBy
    }
  }
`;
const createTest = async ({
  name,
  description,
  parentNode,
  fact,
  startNode,
  result,
  status,
  requiredResults,
  unwantedResults,
  createdBy,
}: {
  name: string;
  description?: string;
  parentNode: string;
  fact?: string | null;
  startNode?: string | null;
  result?: string | null;
  status?: string | null;
  requiredResults?: string | null;
  unwantedResults?: string | null;
  createdBy: string;
}): Promise<Test | undefined> => {
  const { data } = await apollo.mutate({
    mutation: CREATE_TEST,
    variables: {
      name,
      parentNode,
      fact,
      startNode,
      result,
      description,
      status,
      requiredResults,
      unwantedResults,
      createdBy,
    },
  });
  return (data?.createTest as Test[])?.[0];
};

const UPDATE_TEST = gql`
  mutation (
    $uuid: String!
    $name: String!
    $description: String
    $fact: String
    $startNode: String
    $result: String
    $status: String
    $requiredResults: String
    $unwantedResults: String
    $updatedBy: String
  ) {
    updateTest(
      uuid: $uuid
      name: $name
      description: $description
      fact: $fact
      startNode: $startNode
      result: $result
      status: $status
      requiredResults: $requiredResults
      unwantedResults: $unwantedResults
      updatedBy: $updatedBy
    ) {
      uuid
      name
      startNode
      fact
      result
      description
      status
      requiredResults
      unwantedResults
      createdBy
      updatedBy
    }
  }
`;
const updateTest = async ({
  uuid,
  name,
  fact,
  startNode,
  result,
  description,
  status,
  requiredResults,
  unwantedResults,
  updatedBy,
}: {
  uuid: string;
  name: string;
  fact?: string | null;
  startNode?: string | null;
  result?: string | null;
  description?: string | null;
  status?: string | null;
  requiredResults?: string | null;
  unwantedResults?: string | null;
  updatedBy: string;
}): Promise<Test | undefined> => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_TEST,
    variables: {
      uuid,
      name,
      fact,
      startNode,
      result,
      description,
      status,
      requiredResults,
      unwantedResults,
      updatedBy,
    },
  });
  return (data?.updateTest as Test[])?.[0];
};

const DELETE_TEST = gql`
  mutation UPDATE_TEST($uuid: String!) {
    deleteTest(uuid: $uuid) {
      uuid
    }
  }
`;
const deleteTest = async (uuid: string): Promise<Test | undefined> => {
  const { data } = await apollo.mutate({
    mutation: DELETE_TEST,
    variables: {
      uuid,
    },
  });
  return (data?.updateTest as Test[])?.[0];
};

const CREATE_ATOM = gql`
  mutation CREATE_ATOM($type: String!, $name: String!, $parentNode: String!, $comment: String) {
    createAtom(type: $type, name: $name, parentNode: $parentNode, comment: $comment) {
      uuid
      name
      type
      comment
    }
  }
`;
export type CreateAtomPayload = {
  type: string;
  name: string;
  parentNode: string;
  comment?: string;
};
const createAtom = async ({ name, type, comment, parentNode }: CreateAtomPayload): Promise<Atom> => {
  const { data } = await apollo.mutate({
    mutation: CREATE_ATOM,
    variables: {
      name,
      parentNode,
      type,
      comment,
    },
  });
  return data?.createAtom as Atom;
};

const UPDATE_ATOM = gql`
  mutation UPDATE_ATOM($uuid: String!, $name: String!, $type: String!, $comment: String) {
    updateAtom(uuid: $uuid, name: $name, type: $type, comment: $comment) {
      uuid
      name
      type
      comment
    }
  }
`;
export type UpdateAtomPayload = {
  uuid: string;
  name: string;
  type: string;
  comment?: string;
};
const updateAtom = async ({ name, type, comment, uuid }: UpdateAtomPayload): Promise<Atom> => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_ATOM,
    variables: {
      uuid,
      name,
      type,
      comment,
    },
  });
  return data?.updateAtom as Atom;
};

const DELETE_ATOM = gql`
  mutation DELETE_ATOM($uuid: String!) {
    deleteAtom(uuid: $uuid) {
      success
    }
  }
`;
const deleteAtom = async (uuid: string): Promise<Boolean> => {
  const { data } = await apollo.mutate({
    mutation: DELETE_ATOM,
    variables: {
      uuid,
    },
  });
  return data?.deleteAtom;
};

const GET_ATOMS = gql`
  query GET_ATOMS($codeBlockId: String, $suggestedText: String) {
    getAtoms(codeBlockId: $codeBlockId, suggestedText: $suggestedText) {
      uuid
      name
      type
      comment
    }
  }
`;
const getAtomTexts = async ({
  codeBlockId,
  suggestedText,
}: {
  codeBlockId?: string;
  suggestedText?: string;
}): Promise<string[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_ATOMS,
    fetchPolicy: 'no-cache',
    variables: {
      codeBlockId,
      suggestedText,
    },
  });
  const result = data?.getAtoms?.length ? data?.getAtoms?.map((a: Atom) => a.name) : DEFAULT_ATOM_SUGGESTION;
  return result;
};

const getAtoms = async ({
  codeBlockId,
  suggestedText,
}: {
  codeBlockId?: string;
  suggestedText?: string;
}): Promise<Atom[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_ATOMS,
    fetchPolicy: 'no-cache',
    variables: {
      codeBlockId,
      suggestedText,
    },
  });
  return data?.getAtoms ?? [];
};

const GENERATE_CODEBLOCK_DATA = gql`
  query GENERATE_CODEBLOCK_DATA($documentUuid: ID!, $uuid: ID!, $documentShortName: String) {
    generateCodeBlockData(documentUuid: $documentUuid, uuid: $uuid, documentShortName: $documentShortName) {
      data
    }
  }
`;
const generateCodeBlockData = async ({
  documentUuid,
  documentShortName,
  uuid,
}: {
  documentShortName?: string;
  documentUuid: string;
  uuid: string;
}): Promise<string> => {
  const { data } = await apollo.mutate({
    mutation: GENERATE_CODEBLOCK_DATA,
    variables: {
      documentUuid,
      uuid,
      documentShortName,
    },
  });
  return data?.generateCodeBlockData?.data as string;
};

const UPDATE_DOCUMENT = gql`
  query UPDATE_DOCUMENT($uuid: String!, $shortName: String!) {
    updateDocument(uuid: $uuid, shortName: $shortName) {
      displayName
      shortName
    }
  }
`;
const updateDocument = async ({ shortName, uuid }: { shortName: string; uuid: string }): Promise<RealtaDocument> => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_DOCUMENT,
    variables: {
      uuid,
      shortName,
    },
  });
  return data?.updateDocument;
};

const GENERATE_CONDITION = gql`
  query GENERATE_CONDITION($content: String!, $type: String!) {
    generateCondition(content: $content, type: $type) {
      data
    }
  }
`;
const generateCondition = async ({ content, type }: { content: string; type: string }): Promise<string> => {
  const { data } = await apollo.mutate({
    mutation: GENERATE_CONDITION,
    variables: {
      content,
      type,
    },
  });
  return data?.generateCondition?.data;
};

const GET_LINKED_RTP = gql`
  query GET_LINKED_RTP($uuid: String!) {
    getLinkedRtp(uuid: $uuid) {
      name
      description
    }
  }
`;
const getLinkedRtp = async (uuid: string): Promise<LinkedRtp[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_LINKED_RTP,
    variables: {
      uuid,
    },
  });
  return data?.getLinkedRtp;
};

const GET_DOCUMENT_DIFFS = gql`
  query GET_DOCUMENT_DIFFS($uuid: String!) {
    getDocumentDiffs(uuid: $uuid) {
      uuid
      startBoundryPage
      endBoundryPage
      startBoundryY
      endBoundryY
      fullIds
      joinFullId
      parHash
      sectionHash
      content
    }
  }
`;
const getDocumentDiffs = async (uuid: string): Promise<DocumentDiffChange[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_DOCUMENT_DIFFS,
    variables: {
      uuid,
    },
  });
  return data?.getDocumentDiffs;
};

const compareDocuments = async (
  originalDocumentUuid: string,
  updatedDocumentUuid: string
): Promise<DocumentDiffResult> => {
  const [original, updated] = await Promise.all([
    getDocumentDiffs(originalDocumentUuid),
    getDocumentDiffs(updatedDocumentUuid),
  ]);

  const originalFullIdMap = new Map(original.map((item) => [item.joinFullId, item]));
  const updatedFullIdMap = new Map(updated.map((item) => [item.joinFullId, item]));

  const originalParHashMap = new Map(original.map((item) => [item.parHash, item]));
  const updatedParHashMap = new Map(updated.map((item) => [item.parHash, item]));

  // Create maps for each document
  const doc1ChangeMap = new Map<string, DocumentDiffChange>();
  const doc2ChangeMap = new Map<string, DocumentDiffChange>();
  // Create a map for the merged changes, show in diffs view
  const mergedChangeMap = new Map<string, DocumentDiffChange>();
  // Create a map for the table of contents changes
  const tocChangeMap = new Map<string, DocumentDiffChange>();

  // Check for deletions
  original.forEach((item) => {
    if (!updatedFullIdMap.has(item.joinFullId) && !updatedParHashMap.has(item.parHash)) {
      doc1ChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.DELETED,
      });
      mergedChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.DELETED,
      });
      tocChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.DELETED,
      });
    }
  });

  // Check for additions
  updated.forEach((item) => {
    if (!originalFullIdMap.has(item.joinFullId) && !originalParHashMap.has(item.parHash)) {
      doc2ChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.ADDED,
      });
      mergedChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.ADDED,
      });
      tocChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.ADDED,
      });
    }
  });
  original.forEach((item) => {
    if (
      updatedFullIdMap.has(item.joinFullId) &&
      updatedFullIdMap.get(item.joinFullId)!.parHash !== item.parHash &&
      updatedFullIdMap.get(item.joinFullId)!.sectionHash !== item.sectionHash
    ) {
      doc1ChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.UPDATED,
        update: updatedFullIdMap.get(item.joinFullId)!,
      });

      doc2ChangeMap.set(updatedFullIdMap.get(item.joinFullId)!.uuid, {
        ...updatedFullIdMap.get(item.joinFullId)!,
        type: DocumentDiffChangeType.UPDATED,
        update: item,
      });

      mergedChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.UPDATED,
        update: updatedFullIdMap.get(item.joinFullId)!,
      });
    }
    if (
      updatedFullIdMap.has(item.joinFullId) &&
      updatedFullIdMap.get(item.joinFullId)!.sectionHash !== item.sectionHash
    ) {
      tocChangeMap.set(item.uuid, {
        ...item,
        type: DocumentDiffChangeType.UPDATED,
      });
    }
  });

  return {
    doc1ChangeMap,
    doc2ChangeMap,
    mergedChangeMap,
    tocChangeMap,
  };
};

const GET_JURISDICTIONS = gql`
  query GET_JURISDICTIONS($labels: [String!]) {
    getJurisdictions(labels: $labels) {
      data
    }
  }
`;
const getJurisdictions = async (labels?: string[]): Promise<Jurisdiction[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_JURISDICTIONS,
    variables: {
      labels,
    },
  });
  return data?.getJurisdictions;
};

const GET_DATASETS = gql`
  query GET_DATASETS($roles: [String!]) {
    getDatasets(roles: $roles) {
      data
    }
  }
`;
const getDatasets = async (roles?: string[]): Promise<Dataset[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_DATASETS,
    variables: {
      roles,
    },
  });
  return data?.getDatasets;
};

const SEARCH_DOCUMENTS = gql`
  query SEARCH_DOCUMENTS(
    $jdxs: [String]
    $dataset: String
    $searchQuery: String
    $limit: Int
    $page: Int
    $documentUuid: String
    $sectionUuid: String
  ) {
    searchDocuments(
      jdxs: $jdxs
      dataset: $dataset
      searchQuery: $searchQuery
      limit: $limit
      page: $page
      documentUuid: $documentUuid
      sectionUuid: $sectionUuid
    ) {
      page
      limit
      total
      items {
        document {
          uuid
          externalId
          displayName
          shortName
          externalId
          createdBy
        }
        section {
          uuid
          id
          fullId
          startBoundryY
          endBoundryY
          startBoundryPage
          endBoundryPage
          content
        }
        score
        sourcePath
      }
    }
  }
`;
const searchDocuments = async ({
  jdxs,
  dataset,
  searchQuery,
  documentUuid,
  sectionUuid,
  limit,
  page,
}: {
  jdxs?: string[];
  dataset?: string;
  searchQuery?: string;
  documentUuid?: string;
  sectionUuid?: string;
  limit?: number;
  page?: number;
}): Promise<SearchData> => {
  const { data } = await apollo.mutate({
    mutation: SEARCH_DOCUMENTS,
    variables: {
      jdxs,
      dataset,
      searchQuery,
      limit,
      page,
      documentUuid,
      sectionUuid,
    },
  });
  return data?.searchDocuments;
};

const MERGE_DOCUMENTS = gql`
  query ($srcDocumentId: String!, $targetDocumentId: String!) {
    mergeDocuments(srcDocumentId: $srcDocumentId, targetDocumentId: $targetDocumentId) {
      idPath
      src {
        uuid
        startBoundryPage
        endBoundryPage
        startBoundryY
        endBoundryY
      }
      tgt {
        uuid
        startBoundryPage
        endBoundryPage
        startBoundryY
        endBoundryY
      }
      idPathMatch
      sectionHashMatch
      firstParHashMatch
      srcComments {
        sectionId
        documentId
        content
        isResolved
        isDeleted
        isReply
        replies {
          sectionId
          documentId
          content
          isResolved
          isDeleted
          isReply
        }
      }
      tgtComments {
        sectionId
        documentId
        content
        isResolved
        isDeleted
        isReply
        replies {
          sectionId
          documentId
          content
          isResolved
          isDeleted
          isReply
        }
      }
      mergeCandidate {
        isSolved
      }
    }
  }
`;
export type MergeDocumentResult = {
  map: Map<string, DocumentMerge>;
  list: DocumentMerge[];
};
const mergeDocuments = async (srcDocumentId: string, targetDocumentId: string): Promise<MergeDocumentResult> => {
  const { data } = await apollo.mutate({
    mutation: MERGE_DOCUMENTS,
    variables: {
      srcDocumentId,
      targetDocumentId,
    },
  });

  const res: MergeDocumentResult = {
    map: new Map<string, DocumentMerge>(),
    list: data?.mergeDocuments || [],
  };

  res.list.forEach((item) => item.src?.uuid && res.map.set(item.src.uuid, item));

  return res;
};

const SOLVE_MERGE = gql`
  query ($srcNodeId: String!, $tgtNodeId: String!) {
    solveMerge(srcNodeId: $srcNodeId, tgtNodeId: $tgtNodeId) {
      success
    }
  }
`;
const solveMerge = async (srcNodeId: string, tgtNodeId: string): Promise<boolean> => {
  const { data } = await apollo.mutate({
    mutation: SOLVE_MERGE,
    variables: {
      srcNodeId,
      tgtNodeId,
    },
  });

  return data?.mergeDocument.success;
};

const GET_CODE_GEN_DEBUG = gql`
  query ($codeBlockId: String!) {
    getCodeGenDebug(codeBlockId: $codeBlockId) {
      data
    }
  }
`;
const getCodeGenDebug = async (codeBlockId: string): Promise<CodeGenDebug | null> => {
  const { data } = await apollo.mutate({
    mutation: GET_CODE_GEN_DEBUG,
    variables: {
      codeBlockId,
    },
  });
  if (data?.getCodeGenDebug?.data) {
    return JSON.parse(data.getCodeGenDebug.data);
  }
  return null;
};

const CREATE_TAG_QUERY = gql`
  mutation CREATE_TAG_QUERY($nodeUuid: String!, $name: String!) {
    createTag(nodeUuid: $nodeUuid, name: $name) {
      uuid
      name
    }
  }
`;
const createTag = async (nodeUuid: string, name: string): Promise<Tag> => {
  const { data } = await apollo.mutate({
    mutation: CREATE_TAG_QUERY,
    variables: {
      nodeUuid,
      name,
    },
  });
  return data?.createTag;
};

const GET_TAGS_BY_NODE_UUID = gql`
  query GET_TAGS_BY_NODE_UUID($nodeUuid: String!) {
    getTagsByNodeUuid(nodeUuid: $nodeUuid) {
      uuid
      name
    }
  }
`;
const getTagsByNodeUuid = async (nodeUuid: string): Promise<Tag[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_TAGS_BY_NODE_UUID,
    variables: {
      nodeUuid,
    },
  });
  return data?.getTagsByNodeUuid;
};

const DELETE_TAG_FROM_NODE = gql`
  mutation DELETE_TAG_FROM_NODE($tagUuid: String!, $nodeUuid: String!) {
    deleteTagFromNode(tagUuid: $tagUuid, nodeUuid: $nodeUuid) {
      success
    }
  }
`;
const deleteTagFromNode = async (tagUuid: string, nodeUuid: string): Promise<boolean> => {
  const { data } = await apollo.mutate({
    mutation: DELETE_TAG_FROM_NODE,
    variables: {
      tagUuid,
      nodeUuid,
    },
  });
  return data?.deleteTagFromNode?.success;
};

export const documentService = {
  getDocument,
  getAllDocumentMeta,
  getDocumentSectionsByPage,
  getSection,
  getDocumentHeadingSections,
  getSubSectionsOfSection,
  getDocumentParagraphsByPage,
  getCodeBlockByNodeId,
  createCodeBlock,
  updateCodeBlock,
  getSectionHierarchy,
  getSectionReferences,
  createTest,
  getTestsByNodeId,
  updateTest,
  deleteTest,
  createAtom,
  updateAtom,
  deleteAtom,
  getAtoms,
  getAtomTexts,
  getNodeSubSectionComments,
  generateCodeBlockData,
  updateDocument,
  generateCondition,
  getDocumentDiffs,
  compareDocuments,
  getLinkedRtp,
  getJurisdictions,
  getDatasets,
  searchDocuments,
  getDocumentsOfFolder,
  reviewCodeBlock,
  mergeDocuments,
  solveMerge,
  getCodeGenDebug,
  createTag,
  getTagsByNodeUuid,
  deleteTagFromNode,
};
